<template>
  <v-container id="search-results-orders" fluid tag="section" elevation="0">
    <v-card elevation="0">
      <v-tabs v-model="currentTab" fixed-tabs>
        <v-tab v-for="item in tabs" :key="item.key" :href="'#tab-' + item.key">
          {{ item.name }} [{{ counters[item.key] }}]
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item
          v-for="tab in tabs"
          :key="tab.key"
          :value="'tab-' + tab.key"
          eager
        >
          <component
            :is="current[tab.key]"
            v-show="!loading[tab.key]"
            :search-phrase="currentSearch"
            :limit="true"
            v-bind="params"
            @edit="item => onEdit(tab, item)"
            @close="() => backToList(tab)"
          />
          <p v-show="loading[tab.key]">
            <v-progress-linear indeterminate color="accent" />
          </p>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import orderList from '@/views/dashboard/components/orders/orderList';
import orderForm from '@/views/dashboard/components/orders/orderForm';
import customerList from '@/views/dashboard/components/customers/customerList';
import customerForm from '@/views/dashboard/components/customers/customerForm';
import toolList from '@/views/dashboard/components/tools/toolList';
import toolForm from '@/views/dashboard/components/tools/toolForm';

export default {
  data: function() {
    return {
      currentTab: 'tab-orders',

      current: { orders: orderList, customers: customerList, tools: toolList },
      list: { orders: orderList, customers: customerList, tools: toolList },
      form: { orders: orderForm, customers: customerForm, tools: toolForm },
      // currentForm: null,
      params: null,
      currentSearch: null,
      counters: { orders: 0, customers: 0, tools: 0 },
      loading: { orders: true, customers: true, tools: true }
    };
  },
  computed: {
    tabs() {
      return [
        { key: 'orders', name: this.$t('orders.header') },
        { key: 'customers', name: this.$t('customers.header') },
        { key: 'tools', name: this.$t('tools.header') }
      ];
    }
  },
  watch: {
    '$route.params': 'updateSearch'
  },
  mounted() {
    console.log('Search.mounted()');
    this.$root.$on('orders:total', this.onOrdersTotal);
    this.$root.$on('tools:total', this.onToolsTotal);
    this.$root.$on('customers:total', this.onCustomersTotal);
    this.updateSearch();
  },
  beforeDestroy() {
    this.$root.$off('orders:total', this.onOrdersTotal);
    this.$root.$off('tools:total', this.onToolsTotal);
    this.$root.$off('customers:total', this.onCustomersTotal);
  },
  methods: {
    onEdit(tab, item) {
      console.log(`OnEdit; tab:${tab}, item:${item}`);
      this.current[tab.key] = this.form[tab.key];
      this.params = { item: item };
    },
    backToList(tab) {
      this.current[tab.key] = this.list[tab.key];
      this.setParams();
    },
    onOrdersTotal(total) {
      this.$set(this.counters, 'orders', total);
      this.$set(this.loading, 'orders', false);
    },
    onToolsTotal(total) {
      this.$set(this.counters, 'tools', total);
      this.$set(this.loading, 'tools', false);
    },
    onCustomersTotal(total) {
      this.$set(this.counters, 'customers', total);
      this.$set(this.loading, 'customers', false);
    },
    setParams() {
      this.params = {};
    },
    updateSearch() {
      console.log('Search.updateSearch()');
      const phrase = this.$route.params.search;
      console.log('Search.phrase:', phrase);
      if (phrase != this.currentSearch) {
        this.loading = { orders: true, customers: true, tools: true };
        this.current = {
          orders: orderList,
          customers: customerList,
          tools: toolList
        };
        this.counters = { orders: 0, customers: 0, tools: 0 };
        this.currentSearch = phrase;
        this.currentTab = 'tab-Orders';
        this.setParams();
      }
    }
  }
};
</script>
