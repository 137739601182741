<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    :items="param_definition.values_allowed"
    :menu-props="{ maxHeight: '400' }"
    :hint="tparamName"
    persistent-hint
    dense
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: ['param_definition', 'value', 'hint'],

  computed: {
    selectString() {
      return (
        this.param_definition.param_type === 'string' &&
        this.param_definition.values_allowed.length > 0
      );
    },
    tparamName() {
      const key = `tools.params.${this.param_definition.param_name}.name`;
      if (this.$te(key)) {
        return `${this.$t(key)}`;
      }
      return `${this.param_definition.param_name}`;
    }
  },
  methods: {}
};
</script>

<style></style>
