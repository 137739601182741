<template>
  <div>
    <v-card-title>
      <v-select
        v-model="key"
        style="padding-right:10px"
        :items="fields"
        item-text="text"
        item-value="value"
        :label="$t('orders.select-field')"
        @change="onFieldSelected(key)"
      />
      <v-select
        v-model="filterOperation"
        style="padding-right:10px"
        :items="ops"
        items-text="text"
        items-value="value"
        :label="$t('orders.select-operation')"
      />

      <v-text-field
        v-if="isStringField || isNumericField"
        v-model="value"
        style="padding-right:10px"
        :label="$t('orders.enter-keyword')"
        single-line
      />

      <date-picker
        v-if="isDateField"
        v-model="value"
        :label="$t('orders.select-date')"
        :close-on-content-click="true"
      />

      <v-select
        v-if="isSelectField"
        v-model="value"
        :items="selectValues"
        items-text="text"
        items-value="value"
      />

      <v-spacer />
      <v-btn color="primary" dark class="mb-2" @click="setFilter">
        {{ $t('orders.add-filter') }}
      </v-btn>
    </v-card-title>

    <div
      v-for="(filter, index) in filters"
      :key="index"
      class="
            accent
            v-btn v-btn--rounded            
            elevation-2
            v-size--default             
          "
    >
      {{ filter.fieldName }} {{ filter.operationName }}
      {{ filter.tValue }}
      <v-spacer />
      <button
        type="button"
        aria-label="clear icon"
        class="v-icon notranslate v-icon--link mdi mdi-close theme--light"
        @click="clearFilter(index)"
      />
    </div>
  </div>
</template>

<script>
import OrderMixin from './orderMixin.js';
import DatePicker from '@/components/base/DatePicker.vue';

export default {
  components: {
    DatePicker
  },
  mixins: [OrderMixin],
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  data: function() {
    return {
      filterSet: [...this.filters],
      value: null,
      key: 'Select field',
      filterOperation: 'Select operation',
      field: null,
      fields: [
        {
          value: 'status',
          text: this.$t('orders.field.status'),
          type: 'status'
        },
        {
          value: 'order_no',
          text: this.$t('orders.field.order_no'),
          type: 'string'
        },
        {
          value: 'item_no',
          text: this.$t('orders.field.item_no'),
          type: 'string'
        },
        {
          value: 'description',
          text: this.$t('orders.field.description'),
          type: 'string'
        },
        {
          value: 'comments',
          text: this.$t('orders.field.comments'),
          type: 'string'
        },
        {
          value: 'die_cut_id',
          text: this.$t('orders.field.die_cut_id'),
          type: 'string'
        },
        {
          value: 'braille_id',
          text: this.$t('orders.field.braille_id'),
          type: 'string'
        },
        {
          value: 'client_ro',
          text: this.$t('orders.field.client_ro'),
          type: 'string'
        },
        {
          value: 'braille_enabled',
          text: this.$t('orders.field.braille_enabled'),
          type: 'bool'
        },
        {
          value: 'braille_text',
          text: this.$t('orders.field.braille_text'),
          type: 'string'
        },
        {
          value: 'client_id',
          text: this.$t('orders.field.client_id'),
          type: 'string'
        },
        {
          value: 'client_order_no',
          text: this.$t('orders.field.client_order_no'),
          type: 'string'
        },
        {
          value: 'due_date',
          text: this.$t('orders.field.due_date'),
          type: 'date'
        },
        {
          value: 'when_created',
          text: this.$t('orders.field.when_created'),
          type: 'date'
        },
        {
          value: 'varnish_free_area',
          text: this.$t('orders.field.varnish_free_area'),
          type: 'bool'
        },
        {
          value: 'pms',
          text: this.$t('orders.field.pms'),
          type: 'bool'
        },
        {
          value: 'quantity',
          text: this.$t('orders.field.quantity'),
          type: 'number'
        }
      ],
      ops: [],
      textOps: [
        { value: '=', text: this.$t('orders.filter-ops.equals') },
        { value: '__contains', text: this.$t('orders.filter-ops.contains') },
        {
          value: '__startswith',
          text: this.$t('orders.filter-ops.starts-with')
        },
        { value: '__endswith', text: this.$t('orders.filter-ops.ends-with') }
      ],
      numOps: [
        { value: '=', text: '=' },
        { value: '__lt', text: '<' },
        { value: '__lte', text: '<=' },
        { value: '__gt', text: '>' },
        { value: '__gte', text: '>=' }
      ],
      dateOps: [
        { value: '=', text: '=' },
        { value: '__lt', text: '<' },
        { value: '__lte', text: '<=' },
        { value: '__gt', text: '>' },
        { value: '__gte', text: '>=' }
      ],
      eqOps: [{ value: '=', text: '=' }],
      boolValues: [
        { value: false, text: this.$t('no') },
        { value: true, text: this.$t('yes') }
      ]
      //   statusValues: [
      //     { value: false, text: this.$t('new') },
      //     { value: true, text: this.$t('old') }
      //   ]
    };
  },
  computed: {
    isSelectField() {
      return this.field?.type == 'bool' || this.field?.type == 'status';
    },
    isDateField() {
      return this.field?.type == 'date';
    },
    isStringField() {
      return this.field?.type == 'string';
    },
    isNumericField() {
      return this.field?.type == 'number';
    },
    selectValues() {
      if (this.field?.type == 'bool') {
        return this.boolValues;
      }
      if (this.field?.type == 'status') {
        return this.statuses;
      }
      return [];
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {},
    onFieldSelected(field) {
      this.field = this.fields.find(f => f.value == field);
      console.log('Filter-field:', field);
      switch (this.field?.type) {
        case 'number':
          this.ops = this.numOps;
          break;
        case 'string':
          this.ops = this.textOps;
          break;
        case 'bool':
          this.ops = this.eqOps;
          break;
        default:
          this.ops = this.eqOps;
          break;
      }
    },
    clearFilter(num) {
      this.filterSet.splice(num, 1);
      this.$emit('changed', this.filterSet);
    },
    setFilter() {
      if (!this.filterSet) this.filterSet = [];

      let value = null;
      if (this.key == 'due_date') {
        value = new Date(this.value).toISOString().substring(0, 10);
      } else if (this.key == 'when_created') {
        value = new Date(this.value).toISOString();
      } else {
        value = this.value;
      }

      if (!value) {
        return;
      }

      const translatedValue =
        this.selectValues.find(v => v.value == value)?.text || value;
      console.log('translatedValue:', translatedValue);

      this.filterSet.push({
        field: this.key,
        fieldName: this.field.text,
        operation: this.filterOperation,
        operationName: this.operationName(this.filterOperation),
        value: value,
        tValue: translatedValue
      });

      this.key = this.value = this.filterOperation = null;
      this.$emit('changed', this.filterSet);
    },
    operationName(op) {
      let operation = this.textOps.find(o => o.value == op);
      if (operation) {
        return operation.text;
      }
      operation = this.numOps.find(o => o.value == op);
      if (operation) {
        return operation.text;
      }
      return null;
    }
  }
};
</script>

<style></style>
