<template>
  <div class="Tools">
    <template>
      <v-card elevation="0" class="mx-2">
        <v-card-title>
          <v-text-field
            v-show="!limit"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          dense
          :loading="loading"
          :loading-text="$t('loading')"
          :headers="headers"
          :items="tools"
          :items-per-page="20"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
            'items-per-page-text': $t('table.per-page')
          }"
          :no-data-text="$t('table.no-data')"
          :search="search"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          :options.sync="options"
          :server-items-length="total"
          @click:row="expandRow"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>Tools</v-toolbar-title>
              <v-divider class="mx-4" inset vertical />
              <v-spacer />
              <v-btn
                v-if="hasPermission('api.add_tool')"
                v-show="!limit"
                color="primary"
                dark
                class="mb-2 mr-2"
                @click="onNewTool"
              >
                {{ $t('tools.new') }}
                <v-icon right dark>
                  mdi-plus
                </v-icon>
              </v-btn>

              <v-btn
                v-show="!limit"
                color="primary"
                dark
                class="mb-2"
                @click="onExport"
              >
                {{ $t('export') }}
                <v-icon right dark>
                  mdi-file-excel
                </v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template #[`item.type`]="{ item }">
            {{ tType(item.type) }}
          </template>
          <template #[`item.status`]="{ item }">
            {{ tStatus(item.status) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              v-if="hasPermission('api.change_tool')"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="hasPermission('api.delete_tool')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <more-info :item="item" :headers="headers" />
            </td>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>
import ToolService from '@/services/ToolService.js';
import Mixin from './toolsMixin.js';

export default {
  components: {
    moreInfo: () => import('@/views/dashboard/components/tools/moreInfo.vue')
  },
  mixins: [Mixin],
  props: {
    searchPhrase: {
      type: String,
      required: false
    },
    limit: {
      type: Boolean,
      required: false
    }
  },
  data: function() {
    return {
      searchTimeout: null,
      loading: false,
      search: '',
      expanded: [],

      tools: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      options: {},
      total: 0,

      alertTimestamp: 0
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('tools.name'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('tools.type'),
          value: 'type',
          sortable: true
        },
        {
          text: this.$t('tools.format'),
          value: 'format',
          sortable: true
        },
        {
          text: this.$t('tools.nesting'),
          value: 'nesting',
          sortable: false
        },
        {
          text: this.$t('tools.dimensions'),
          value: 'dimensions',
          sortable: false
        },
        {
          text: this.$t('tools.cardboard'),
          value: 'cardboard',
          sortable: false
        },
        {
          text: this.$t('tools.location'),
          value: 'location',
          sortable: false
        },
        {
          text: this.$t('status'),
          value: 'status',
          sortable: true
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  watch: {
    options: {
      handler() {
        this.loadItems();
      },
      deep: true
    },
    search: {
      handler() {
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 500);
      }
    },
    searchPhrase() {
      this.loadItems();
    }
  },

  async activated() {
    await this.mixinInit();
  },

  mounted() {
    this.$root.$on('tool_updated', this.onToolUpdated);
    this.$root.$on('tool_added', this.onToolAdded);
  },
  beforeDestroy() {
    this.$root.$off('tool_updated', this.onToolUpdated);
    this.$root.$off('tool_added', this.onToolAdded);
  },
  methods: {
    async loadItems() {
      this.loading = true;

      ToolService.getTools(
        this.options,
        this.searchPhrase ? this.searchPhrase : this.search
      )
        .then(response => {
          [this.tools, this.total] = response;
          this.$root.$emit('tools:total', this.total);
          this.tools = this.tools.map(t => this.transform(t), this);
          // console.log('tools:', this.tools);
        })
        .catch(err => {
          this.showError(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editItem(item) {
      this.$emit('edit', Object.assign({}, item));
    },

    onToolAdded(tool) {
      console.log('onToolAdded:', tool);
      this.tools.push(this.transform(tool));
    },

    onToolUpdated(tool) {
      console.log('onToolUpdated:', tool);
      let index = this.tools.findIndex(c => c.id == tool.id);
      if (index != -1) {
        Object.assign(this.tools[index], this.transform(tool));
      }
    },

    async deleteItem(item) {
      console.log('delete tool:', item);
      const res = await this.$swal({
        html: this.$t('tools.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.tools.indexOf(item);

      try {
        let result = await ToolService.deleteTool(item);
        console.log('result: ', result);
        this.tools.splice(idx, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    onNewTool() {
      this.$emit('new');
    },

    async onExport() {
      await ToolService.excel(
        this.options,
        this.searchPhrase ? this.searchPhrase : this.search
      );
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    }
  }
};
</script>
