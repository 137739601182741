<template>
  <div class="Customers">
    <template>
      <v-card elevation="0" class="mx-2">
        <v-card-title>
          <v-text-field
            v-show="!limit"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          dense
          :loading="loading"
          :loading-text="$t('loading')"
          :headers="headers"
          :items="customers"
          :items-per-page="20"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
            'items-per-page-text': $t('table.per-page')
          }"
          :no-data-text="$t('table.no-data')"
          :search="search"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          @click:row="expandRow"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('customers.header') }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical />
              <v-spacer />
              <v-btn
                v-if="hasPermission('api.add_customer')"
                v-show="!limit"
                color="primary"
                dark
                class="mb-2 mr-2"
                @click="onNewCustomer"
              >
                {{ $t('customers.new') }}
                <v-icon right dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn
                v-show="!limit"
                color="primary"
                dark
                class="mb-2"
                @click="onExport"
              >
                {{ $t('export') }}
                <v-icon right dark>
                  mdi-file-excel
                </v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template #[`item.types`]="{ item }">
            {{ tTypes(item.types) }}
          </template>

          <template #[`item.actions`]="{ item }">
            <v-icon
              v-if="hasPermission('api.change_customer')"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="hasPermission('api.delete_customer')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <more-info :item="item" :headers="headers" />
            </td>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>
import CustomerMixin from './customerMixin.js';
import CustomerService from '@/services/CustomerService.js';

export default {
  components: {
    moreInfo: () =>
      import('@/views/dashboard/components/customers/moreInfo.vue')
  },
  mixins: [CustomerMixin],
  props: {
    searchPhrase: {
      type: String,
      required: false,
      default: ''
    },
    limit: {
      type: Boolean,
      required: false
    }
  },
  data: function() {
    return {
      loading: false,
      search: '',
      expanded: [],

      customers: [],
      editedIndex: -1,
      editedItem: null,
      defaultItem: {
        name: '',
        short_name: '',
        external_id: '',
        types: [],
        nip: '',
        regon: '',
        due_days: null,
        default_varnish: '',
        addresses: []
      }
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('customers.name'),
          align: 'start',
          value: 'name'
        },
        {
          text: this.$t('customers.short-name'),
          value: 'short_name'
        },
        {
          text: this.$t('customers.external-id'),
          value: 'external_id',
          sortable: true
        },
        {
          text: this.$t('customers.types'),
          value: 'types',
          sortable: false
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    }
  },

  watch: {
    searchPhrase() {
      this.loadItems();
    }
  },

  mounted() {
    this.$root.$on('customer_updated', this.onCustomerUpdated);
    this.$root.$on('customer_added', this.onCustomerAdded);
    this.loadItems();
  },
  beforeDestroy() {
    this.$root.$off('customer_updated', this.onCustomerUpdated);
    this.$root.$off('customer_added', this.onCustomerAdded);
  },
  methods: {
    async loadItems() {
      this.loading = true;
      try {
        this.customers = await CustomerService.getCustomers(
          null,
          this.searchPhrase
        );
        console.log('loaded customers: ', this.customers);
        console.log('len: ', this.customers.length);
        this.$root.$emit('customers:total', this.customers.length);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    editItem(item) {
      this.$emit('edit', Object.assign({}, item));
    },

    onNewCustomer() {
      this.$emit('new');
    },

    onCustomerAdded(customer) {
      console.log('onCustomerAdded:', customer);
      this.customers.push(customer);
    },

    onCustomerUpdated(customer) {
      let index = this.customers.findIndex(c => c.id == customer.id);
      console.log('onCustomerUpdated:', customer, 'index:', index);
      if (index != -1) {
        Object.assign(this.customers[index], customer);
      }
    },

    async deleteItem(item) {
      console.log('delete customer:', item);
      const res = await this.$swal({
        html: this.$t('customers.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.customers.indexOf(item);

      try {
        let result = await CustomerService.deleteCustomer(item);
        console.log('result: ', result);
        this.items.splice(idx, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    async onExport() {
      await CustomerService.excel(
        this.searchPhrase ? this.searchPhrase : this.search ? this.search : ''
      );
    }
  }
};
</script>
