export default {
  data: function() {
    return {
      types: []
    };
  },
  computed: {
    statuses() {
      return [
        { value: 'in design', text: this.$t('tools.status.in design') },
        { value: 'ordered', text: this.$t('tools.status.ordered') },
        { value: 'broken', text: this.$t('tools.status.broken') },
        { value: 'delivered', text: this.$t('tools.status.delivered') }
      ];
    }
  },
  methods: {
    async mixinInit() {
      const types = await this.$store.dispatch('getToolTypes');
      this.types = types.map(t => {
        return {
          ...t,
          tName: this.tType(t.name)
        };
      });
    },
    tType(type) {
      const key = `tools.types.${type}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return type;
    },
    tStatus(status) {
      const key = `tools.status.${status}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return status;
    },
    transform(t) {
      const tool = {
        ...t,
        when_created: this.dateFromISO8601(t.when_created),
        when_ordered: this.dateFromISO8601(t.when_ordered),
        when_delivered: this.dateFromISO8601(t.when_delivered),
        dimensions: `${t.dimension_x}x${t.dimension_y}x${t.dimension_z}`,
        cardboard: t.params.filter(
          p => p.definition.param_name == 'cardboard'
        )?.[0]?.value,
        format: t.params.filter(p => p.definition.param_name == 'format')?.[0]
          ?.value
        // tType: this.tType(t.type),
        // tStatus: this.tStatus(t.status)
      };

      return tool;
    }
  }
};
