const mixin = {
  async mounted() {
    console.log('mixin.mounted()');
    try {
      this.types = await this.$store.dispatch('getCustomerTypes');
    } catch (err) {
      this.showError(this, err);
    }
  },
  data: function() {
    return {
      types: null
    };
  },
  methods: {
    tType(type) {
      const key = `customers.customer-types.${type}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return `${type}`;
    },
    tTypes(types) {
      return types.map(this.tType).join(', ');
    }
  },
  computed: {
    typesToSelect() {
      console.log('types: ', this.types);
      return this.types.map(t => {
        return {
          text: this.tType(t.name),
          value: t.name
        };
      });
    }
  }
};
export default mixin;
