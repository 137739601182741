<template>
  <v-layout row wrap class="ml-1 mt-3 mb-3">
    <v-card
      v-for="(status, i) in nonZeroStatuses"
      :key="i"
      color="primary"
      dark
      elevation="0"
      max-width="240"
      min-width="240"
      class="ma-1 pa-0"
    >
      <v-card-actions class="ma-0 pa-0">
        <v-list-item class="grow">
          <v-row align="center">
            <v-col class="text-left" cols="5">
              <span class="label">{{ tStatus(status.name) }}</span>
            </v-col>
            <v-col class="text-right">
              #{{ getStats(status.name).count }} /
              {{ getStats(status.name).quantity }}
              {{ $t('orders.pieces-short') }}
            </v-col>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import OrderService from '@/services/OrderService.js';
import OrderMixin from './orderMixin.js';

export default {
  mixins: [OrderMixin],
  props: {},
  data: function() {
    return {
      statusList: [
        { name: 'new', label: 'New' },
        { name: 'checked', label: 'Checked' },
        { name: 'ready to print', label: 'Ready' },
        { name: 'in production', label: 'In prod.' },
        { name: 'on hold', label: 'On hold' },
        { name: 'finished', label: 'Finished' }
      ],
      stats: [],
      refreshKey: 0,
      loading: false,
      refreshInterval: null,
      refreshOn: true
    };
  },
  computed: {
    nonZeroStatuses() {
      return this.statusList.filter(i => this.anyWithStatus(i.name));
    }
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.refreshOn = false;
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        {
          const rsp = await OrderService.getStats();
          this.stats = rsp;
        }
        this.refreshKey = Date.now();
      } catch (err) {
        console.log('err:', err);
      } finally {
        this.loading = false;
        let self = this;
        if (!this.refreshInterval && this.refreshOn) {
          self.refreshInterval = setInterval(() => {
            self.load();
          }, 60000);
        }
      }
    },
    anyWithStatus(status) {
      return this.stats.find(s => s.status == status);
    },

    getStats(status) {
      const s = this.stats.find(s => s.status == status);
      return s || { status: status, count: '...', quantity: '...' };
    }
  }
};
</script>

<style lang="sass">
.v-chip__content
    font-size: .9em
.label
    text-weight: bold !important
</style>
