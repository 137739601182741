<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('customers.name')"
              >
                <v-text-field
                  ref="name"
                  v-model="item.name"
                  :label="$t('customers.name')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('customers.short-name')"
              >
                <v-text-field
                  v-model="item.short_name"
                  :label="$t('customers.short-name')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('customers.external-id')"
              >
                <v-text-field
                  v-model="item.external_id"
                  :label="$t('customers.external-id')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                v-if="types"
                v-model="item.types"
                :items="typesToSelect"
                :menu-props="{ maxHeight: '400' }"
                :hint="$t('select-hint')"
                multiple
                :label="$t('customers.types')"
                persistent-hint
                dense
                @keydown.esc="onCancel"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="item.nip"
                :label="$t('customers.nip')"
                dense
                @keydown.esc="onCancel"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="item.regon"
                :label="$t('customers.regon')"
                dense
                @keydown.esc="onCancel"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ between: { min: 1, max: 100 } }"
                :name="$t('customers.due-days')"
              >
                <v-text-field
                  v-model="item.due_days"
                  :label="$t('customers.due-days')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('customers.default-varnish')"
              >
                <v-text-field
                  v-model="item.default_varnish"
                  :label="$t('customers.default-varnish')"
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-treeview
                dense
                :active.sync="addressSelection"
                :items="items"
                open-all
                hoverable
                @keydown.esc="onCancel"
              >
                <template #prepend="{ item }">
                  <v-icon v-if="!item.children">
                    mdi-city
                  </v-icon>
                </template>
                <template slot="label" slot-scope="{ item }">
                  <a @click="addressClicked(item)">{{ item.name }}</a>
                </template>
              </v-treeview>
            </v-col>
            <v-col cols="8">
              <customer-address
                v-if="selected"
                :value="selected"
                :is-new="isAddressNew"
                @delete="deleteAddress"
                @cancel="onCancel"
              />
            </v-col>
          </v-row>

          <v-btn class="ma-2 primary" @click="addAddress">
            {{ $t('customers.add-address') }}
            <v-icon right dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </validation-observer>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="onCancel()">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn color="primary" text @click="onSave()">
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import CustomerMixin from './customerMixin.js';
import CustomerService from '@/services/CustomerService.js';

export default {
  components: {
    customerAddress: () => import('./customerAddress.vue')
  },
  mixins: [CustomerMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    customValidation: {
      type: Function,
      required: false
    }
  },
  data: function() {
    return {
      item: Object.assign({}, this.value),
      addressSelection: [],
      deliveryDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      newAddresses: new Set(),
      initialized: false
    };
  },
  computed: {
    isNew() {
      return !(this.value.id > 0);
    },
    items() {
      return [
        {
          name: this.$t('customers.addresses'),
          children: this.addresses
        }
      ];
    },

    title() {
      if (this.isNew) {
        return this.$t('customers.new');
      }
      return this.$t('customers.edit', { customer: this.item.name });
    },

    addresses() {
      let res = this.item.addresses.map(a => ({
        id: a.id,
        name: a.name,
        active: a.active
      }));
      return res;
    },

    selected() {
      if (!this.item.addresses.length) return undefined;
      const id = this.addressSelection[0];
      return this.item.addresses.find(a => a.id === id);
    },

    isAddressNew() {
      let id = this.selected.id;
      return this.newAddresses.has(id);
    }
  },
  watch: {
    value: {
      async handler(val, oldVal) {
        if (val === oldVal) return;
        this.item = Object.assign({}, val);
        this.newAddresses = new Set();
        this.selectFirstAddress();
      },
      deep: true
    }
  },
  async mounted() {
    await this.init();
    this.selectFirstAddress();
    this.focusInput();
    this.$refs.observer.reset();
  },
  async activated() {
    await this.init();
    this.selectFirstAddress();
    this.focusInput();
    this.$refs.observer.reset();
  },
  methods: {
    async init() {
      if (this.initialized) {
        console.log('init skipped (already initialized)');
      }
      this.initialized = true;
    },
    selectFirstAddress() {
      if (this.item.addresses.length > 0) {
        this.addressSelection = [this.item.addresses[0].id];
      }
    },
    addAddress() {
      let newAddress = Object.assign({}, this.newAddress());
      this.item.addresses.push(newAddress);
      this.addressSelection = [newAddress.id];
      this.newAddresses.add(newAddress.id);
    },
    newAddress() {
      return {
        id: uuidv4(),
        name: 'unnamed',
        address: '',
        contact: '',
        phone: '',
        email: '',
        active: true
      };
    },
    deleteAddress(address) {
      console.log('delete address:', address);
      if (this.newAddresses.has(address.id)) {
        this.newAddresses.delete(address.id);
        this.item.addresses = this.item.addresses.filter(
          a => a.id != address.id
        );
        this.selectFirstAddress();
      }
    },
    async addressClicked(item) {
      console.log('clicked:', item);
      const result = await this.$refs.observer.validate();
      if (result) {
        this.addressSelection = [item.id];
      }
    },
    async onCancel() {
      this.initialized = false;
      this.$refs.observer.reset();
      this.$emit('close');
    },

    async onSave() {
      const result = await this.$refs.observer.validate();
      if (!result) return;

      if (this.customValidation) {
        let err = this.customValidation(this.item, this.types);
        if (err) {
          this.showError(this, err);
          return;
        }

        // console.log("Custom")
      }
      try {
        if (!this.isNew) {
          let result = await CustomerService.patchCustomer(this.item);
          console.log('result: ', result);
          this.$root.$emit('customer_updated', result.data); // use central event hub to notify siblign container

          // Object.assign(this.customers[this.editedIndex], item);
        } else {
          let result = await CustomerService.postCustomer(this.item);
          console.log('result: ', result);
          this.$root.$emit('customer_added', result.data); // use central event hub to notify siblign container
          this.$emit('customer_added', result.data);

          // this.customers.push(result.data);
        }
        this.initialized = false;
        this.$emit('close');
      } catch (err) {
        console.log('err:', err);
        this.showError(this, err);
      }
    },
    focusInput() {
      this.$refs.name.focus();
    }
  }
};
</script>

<style></style>
